export const GLOBALS = {
  ENV:  "dev",
  URL: "https://dev-ops.cinesend.com",
  API_URL: "https://dev-api.cinesend.com/api",
  ACCOUNT_REDIRECT_URL: "https://dev-accounts.cinesend.com/login",
  CHROME_CAST_STYLESHEET_URL: "https://s3.amazonaws.com/bitmovin-cast-css/development/styles.min.css",
  S3_PUBLIC: "https://cinesend-public-assets.s3.amazonaws.com",
  S3_ACCESS_KEY: "AKIAWBDOD5OSBJN5DA4P",
  S3_ACCELERATE_ENDPOINT: "https://cinesend-dev.s3-accelerate.amazonaws.com",
  BITMOVIN_API_KEY: "2a8b1f24-2c3c-4ab1-adeb-2b89af5171f0",
}